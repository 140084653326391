import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import Bottom from "../../components/Bottom";
import Navigation from "../../components/Navigation";
import QuestionBox from "../../components/QuestionBox";
import { NavigationContext } from "../../providers/NavigationProvider";
import { getOrganizationInfo } from "../../graphql/queries/General/getOrganizationInfo";
import { updateOrganization } from "../../graphql/mutations/Organization/updateOrganization";
import InternalQuestionBox from "../InternalQuestionBox";
import { getUser } from "../../graphql/queries/User/getUser";
import { updateUser } from "../../graphql/mutations/General/updateUser";
import { TIndustryType, UpdateGeneralOrganizationFormData, ShareschemaApprovalEnum, GetUsersQueryResult } from "../../types";
import { IndustryType } from "../../dictionaries";
import { findSelectValue, SelectValues, mapToSelectOption } from "../../utils/form-utils";
import { setGeneralInfoConfirmed } from "../../generalInfoConfirmed";
import MainProductSelect from "../MainProductSelect";
import { IMutationResult } from "../../types/mutations/mutationResult";
import { handleMutationError } from "../../lib/toaster";
import { yupResolver } from "@hookform/resolvers/yup";
import { generalSchema } from "../../schemas";
import { getUsers } from "../../graphql/queries/User/getUsers";
import MunicipalitySelect from "../MunicipalitySelect";

const Container = styled.div`
  grid-column: 2/3;
`;
const IContainer = styled.div`
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-row-gap: ${(props) => props.theme.gap.light};
`;

const IContainerPerson = styled.div`
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-row-gap: ${(props) => props.theme.gap.light};
`;

const Label = styled.label`
  grid-column: 2/3;
  height: auto;
  padding-left: 3em;
`;
const Input = styled.input`
  grid-column: 3/4;
`;

const ConsentDescription = styled.div`
  grid-column: 1;
  display: flex;
`;

const GiveConsent = styled.div`
  grid-column: 1;
  display: block;
  margin-bottom: 0rem;
`;
const ConsentInput = styled.input`
  float: left;
  margin-right: 1rem;
  margin-top: 0.8rem;
  width: 1.1rem;
`;
const ConsentLabel = styled.label`
  float: left;
  height: auto;
  margin-bottom: 1rem;
  width: 90%;
`;

const SelectField = styled.select`
  width: 100%;
`;
const FormError = styled.div`
  grid-column: 3;
  margin-bottom: 1em;
`;

type GeneralProps = {
  orgId: any;
  data: any;
  consentVariants?: boolean;
  schemaId: string;
};
const General: React.FC<GeneralProps> = ({ orgId, data, schemaId }) => {
  const [isReset, setIsReset] = React.useState<boolean>(true);
  const [isWaitingForData, setIsWaitingForData] = React.useState<boolean>(true);
  const [radioState, setRadioState] = React.useState(ShareschemaApprovalEnum.PublicSharing);
  const [privacyConsentState, setPrivacyConsentState] = React.useState(false);
  const context = React.useContext(NavigationContext);
  const { register, control, reset, errors, handleSubmit } = useForm<UpdateGeneralOrganizationFormData>({
    resolver: yupResolver(generalSchema),
  });

  const [organizationInfoResult] = useQuery({
    query: getOrganizationInfo,
    pause: isWaitingForData,
    variables: { organizationId: orgId },
  });

  const [userResult] = useQuery({ query: getUser });

  const [{ data: organizationUsersResult, fetching: organizationUsersFetching }] = useQuery<GetUsersQueryResult>({
    query: getUsers,
    variables: { filterByOrganizationId: orgId },
  });
  const { data: user, fetching: userFetching } = userResult;

  const [, executeUpdateOrganizationMutation] = useMutation<IMutationResult>(updateOrganization);

  const [, executeUpdateUserMutation] = useMutation<IMutationResult>(updateUser);

  const onSubmit: SubmitHandler<UpdateGeneralOrganizationFormData> = async (data) => {
    await executeUpdateUserMutation({
      userId: user.user.id,
      fullName: user.user.fullName,
      privacyConsent: privacyConsentState ? "YES" : "NO",
    }).catch(() => handleMutationError());

    await executeUpdateOrganizationMutation({
      id: orgId,
      orgId: data.organization.orgId,
      name: data.organization.name,
      mainProduct: data.organization.mainProductSelected?.label,
      mainProductId: data.organization.mainProductSelected?.value,
      industryType: findSelectValue<TIndustryType>(data.organization?.industryType, IndustryType),
      industryCode: data.organization.industryCode,
      location: data.organization.location,
      municipalityId: data.organization.municipality ? data.organization.municipality.value.toString() : undefined,
      shareSchemaApproval: radioState,
      contactPersonId: data.organization.contactPersonId ? data.organization.contactPersonId : undefined,
    })
      .then(() => {
        setGeneralInfoConfirmed(true);
        context.handleNav(true);
      })
      .catch(() => handleMutationError());
  };

  React.useEffect(() => {
    if (data) {
      setIsWaitingForData(false);
      setPrivacyConsentState(user?.user.privacyConsent === "YES" ? true : false);
      const res = organizationInfoResult;
      setRadioState(res.data?.organization.shareSchemaApproval);
      if (!res.fetching && !userFetching && !organizationUsersFetching && isReset) {
        reset({
          ...res?.data,
          ...user,
          ...organizationUsersResult,
          organization: {
            ...res?.data?.organization,
            industryType: IndustryType[res?.data?.organization?.industryType as TIndustryType] as TIndustryType,
            mainProductSelected: res.data?.organization?.mainProductSelected
              ? mapToSelectOption(res.data.organization.mainProductSelected.id, res.data.organization.mainProductSelected.name)
              : "",
            municipality: res.data?.organization?.municipality
              ? mapToSelectOption(res.data.organization.municipality.id, res.data.organization.municipality.name)
              : "",
          },
        });

        setIsReset(false);
      }
    }
  }, [data, reset, isReset, organizationInfoResult, userFetching, user, organizationUsersResult, organizationUsersFetching]);
  const isMaterialStreamSchema = useLocation().pathname?.includes("materialflow") ? true : false;

  return (
    <Container>
      <QuestionBox title="Oppdatering av bedriftsopplysninger">
        <InternalQuestionBox>
          <IContainer>
            <Label>Bedrift</Label>
            <Input ref={register} name="organization.name" />
            {errors !== undefined && errors.organization?.name && (
              <FormError className="error">{errors.organization.name.message}</FormError>
            )}
            <Label>Org. nr</Label>
            <Input ref={register} name="organization.orgId" />
            <Label>By/Kommune</Label>
            <MunicipalitySelect control={control} register={register} fieldName="organization.municipality" />
            <Label>Adresse</Label>
            <Input ref={register} name="organization.location" />
            <Label>Type industri</Label>
            <SelectField ref={register} name="organization.industryType">
              <option value="">Vennligst velg</option>
              {SelectValues(IndustryType)}
            </SelectField>

            {isMaterialStreamSchema ? (
              <>
                <Label>Hovedprodukt</Label>
                <MainProductSelect control={control} register={register} fieldName="organization.mainProductSelected" />
              </>
            ) : (
              <>
                <Label>Hovedprodukt</Label>
                <Input ref={register} name="organization.mainProduct" />
              </>
            )}
          </IContainer>
        </InternalQuestionBox>
        <InternalQuestionBox>
          <IContainerPerson>
            <Label>Kontaktperson</Label>
            {
              <select name="organization.contactPersonId" ref={register}>
                <option value="organization.contactPersonId">{"Vennligst velg"}</option>
                {organizationUsersResult?.users.map((organizationUser) => {
                  return (
                    <option key={organizationUser?.id} value={organizationUser?.id}>
                      {organizationUser.fullName}
                    </option>
                  );
                })}
              </select>
            }
          </IContainerPerson>
        </InternalQuestionBox>
      </QuestionBox>
      <QuestionBox title="Samtykke deling av data" isSubHeader={true}>
        <p>
          Under finner du to valg for deling av data som vi ønsker samtykke på fra bedriften du representerer. Dette er i tråd med det som
          tidligere er gitt av samtykke i prosjektet. Verdien av dataunderlaget fra deltagende bedrifter ligger i en mest mulig åpen tilgang
          til informasjon. Til orientering: Data registrert i webskjema er synlig/tilgjengelig for de som til enhver tid administrerer
          dataplattformen. Administrasjonen har skrevet under på en NDA som regulerer bruk av data.
        </p>
        <ConsentDescription>Ja, jeg samtykker til følgende bruk av registrerte data:</ConsentDescription>

        <GiveConsent>
          <ConsentInput
            name="shareSchemaApproval"
            type="radio"
            value={ShareschemaApprovalEnum.MinimumSharing}
            checked={ShareschemaApprovalEnum.MinimumSharing === radioState}
            onChange={(e) => {
              setRadioState(ShareschemaApprovalEnum.MinimumSharing);
            }}
          />
          <ConsentLabel htmlFor="MyLabel">
            MINIMAL DELING: Data for sidestrømmen er kun synlig for bedriften selv (og Eyde). Aggregerte data er likevel med i statistikker
            på webside ol (uten knytning mot bedrift).
          </ConsentLabel>
        </GiveConsent>

        <GiveConsent>
          <ConsentInput
            name="shareSchemaApproval"
            type="radio"
            value={ShareschemaApprovalEnum.ExtendedSharing}
            checked={ShareschemaApprovalEnum.ExtendedSharing === radioState}
            onChange={(e) => {
              setRadioState(ShareschemaApprovalEnum.ExtendedSharing);
            }}
          />
          <ConsentLabel htmlFor="MyLabel">
            UTVIDET DELING: Alle bedrifter som har valgt utvidet deling og som har sine data registeret i databasen får tilgang til
            registrerte data i databasen. Rapporter basert på dataene kan brukes i samarbeidsprosjekter, workshops og annet hvor
            fageksperter, klynger, forskningsinstitutter og andre interessenter deltar (NDA eller tilsvarende kreves underskrevet).
            Eksperter og andre kan få tilgang til ytterliggere data dersom det avtales med den enkelte bedrift. MERK: Du kan begrense
            synlighet av kjemisk innhold i en sidestrøm på komponentnivå. Det gjøres for hver sidestrøm under steg 4 Analyse.
          </ConsentLabel>
        </GiveConsent>

        <GiveConsent>
          <ConsentInput
            name="shareSchemaApproval"
            type="radio"
            value={ShareschemaApprovalEnum.PublicSharing}
            checked={ShareschemaApprovalEnum.PublicSharing === radioState}
            onChange={(e) => {
              setRadioState(ShareschemaApprovalEnum.PublicSharing);
            }}
          />
          <ConsentLabel htmlFor="MyLabel">
            OFFENTLIG DELING: All data registret i webskjema deles offentlig med unntak av begrensinger av enkelte kjemisk elementer i en
            sidestrøm. Det gjøres for hver sidestrøm under steg 4 Analyse.
          </ConsentLabel>
        </GiveConsent>
        <p>
          MERK: Du kan begrense synlighet av kjemisk innhold i en sidestrøm på komponentnivå når du registrerer eller endrer innhold i
          kjemisk analyse. Det gjøres for hver sidestrøm under steg 4 Analyse.
        </p>
      </QuestionBox>
      <QuestionBox title="Personvernerklæring" isSubHeader={true}>
        <GiveConsent>
          <ConsentInput
            name="privacyConsent"
            type="checkbox"
            checked={privacyConsentState}
            onChange={(e) => {
              setPrivacyConsentState(!privacyConsentState);
            }}
          />
          <ConsentLabel htmlFor="MyLabel">
            Jeg har lest og forstått{" "}
            <a href={process.env.REACT_APP_PRIVACY_CONSENT_URL} target="_blank" rel="noreferrer">
              Personvernerklæringen
            </a>
          </ConsentLabel>
        </GiveConsent>
      </QuestionBox>
      <QuestionBox title="Vi bruker informasjonskapsler (cookies)" isSubHeader={true}>
        <p>
          Våre nettsider benytter informasjonskapsler (cookies). De er nødvendige for at nettsiden skal fungere, og lagres derfor uten
          foregående samtykke.
        </p>
      </QuestionBox>
      <Bottom>
        <Navigation navigation={context.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={() => {}} />
      </Bottom>
    </Container>
  );
};

export default General;
